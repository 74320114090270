<div class="svi-matching-filter-group">
  <form [formGroup]="form">
    <div
      *ngIf="elections && elections.length > 1"
      class="form-title"
      i18n="@@filter.selectElection"
    >
      Select Election
    </div>
    <select
      [sviFocus]="this.elections && this.state.election === ''"
      *ngIf="elections && elections.length > 1"
      class="form-control"
      formControlName="election"
    >
      <option value="">-</option>
      <option *ngFor="let election of elections" [value]="election.id">{{ election.name }}</option>
    </select>

    <ng-container *ngIf="districtGroups && districtGroups.length > 0">
      <div class="form-title" i18n="@@filter.selectDistrictGroup">Select District Group</div>
      <select class="form-control" formControlName="districtGroup">
        <option value="">-</option>
        <option *ngFor="let districtGroup of districtGroups" [value]="districtGroup.id">
          {{ districtGroup.name }}
        </option>
      </select>
    </ng-container>
    <!-- <ng-container *ngIf="districts[0]">
      <div class="form-title" i18n="@@filter.selectDistrict">Select District</div>
      <select
        [sviFocus]="this.elections && this.elections.length === 1 && this.state.district === ''"
        #selectDistrict
        class="form-control"
        formControlName="district"
        (change)="trackSelectDistrict($event.target.value)"
      >
        <option value="">-</option>
        <option *ngFor="let district of districts" [value]="district.id">
          {{ district.name }}
        </option>
      </select>
    </ng-container> -->

    <ng-container *ngIf="false && this.state.district">
      <div class="form-title" i18n="@@filter.selectParty">Select Party</div>
      <select class="form-control" formControlName="party">
        <option value="">-</option>
        <option *ngFor="let party of parties" [value]="party.id">{{ party.name }}</option>
      </select>
    </ng-container>

    <span
      *ngIf="config.electorateHintUrl && districts[0]"
      i18n="@@matching.electorateHint"
      class="electorate-hint"
    >
      Still can’t find your electorate? Go to the
      <a class="electorate-hint electorate-link" [href]="config.electorateHintUrl" target="_blank">
        Destination
      </a>
    </span>
  </form>
  <div *ngIf="form.controls.district.value && election['hasPartyMatching']" class="button-group">
    <button
      [disabled]="disabled"
      [ngClass]="{ active: state.responderType === 'Candidate' }"
      (click)="onResponderTypeChange('Candidate')"
      i18n="@@matching.showCandidates"
    >
      Show Candidates
    </button>
    <button
      [disabled]="disabled"
      [ngClass]="{ active: state.responderType === 'Party' }"
      (click)="onResponderTypeChange('Party')"
      i18n="@@matching.showParties"
    >
      Show Parties
    </button>
  </div>
</div>
