import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { CmsService } from '@core/cms.service';
var GetRecommendationCount = require('graphql-tag/loader!./home.page.graphql').GetRecommendationCount;
var HomePage = /** @class */ (function () {
    function HomePage(router, apollo, cms) {
        var _this = this;
        this.router = router;
        this.apollo = apollo;
        this.recommendationCountLabel = '...';
        this.recommendationCount = 0;
        this.sub = this.getRecommendationCount().subscribe(function (count) {
            _this.recommendationCountLabel = count.toLocaleString('en-GB');
            _this.recommendationCount = count;
        });
        this.docs = cms.documents('home').pipe(map(function (docs) { return Object.keys(docs).map(function (key) { return docs[key]; }); }));
    }
    HomePage.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
    };
    HomePage.prototype.startQuestionnaire = function () {
        this.router.navigate(['matching']);
    };
    HomePage.prototype.getRecommendationCount = function () {
        return this.apollo
            .query({
            query: GetRecommendationCount
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return (data.statistics ? data.statistics.recommendationCount : 0);
        }));
    };
    return HomePage;
}());
export { HomePage };
