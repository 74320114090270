import { SVIVoterConfig } from './voter.configuration'

export const environment: SVIVoterConfig = {
  production: true,
  portierUrl: 'https://2024-portier.smartwielen.lu/graphql',
  sharing: {
    pageGrabberUrl: 'http://page-grabber.smartvote.ch/screenshot',
    twitterUsername: 'smartwielen'
  },
  emailerUrl: 'https://7degjxh594.execute-api.eu-central-1.amazonaws.com/production/send-email',
  emailerFromAddress: 'smartvote.org<info@smartvote.org>',
  contactFormToAddress: 'info@smartwielen.lu',
  matomo: {
    url: 'https://analytics.smartvote.ch',
    siteId: 18
  },
  cms: {
    repo: 'smartvote-international',
    version: 'Master',
    languageMapping: {
      en: 'en-us',
      de: 'de-ch',
      fr: 'fr-ch',
      pt: 'it-ch',
      lb: 'lt-lu'
    },
    tags: ['2024-lux', 'voter']
  },
  profileModuleConfiguration: {
    hasSmartspider: true,
    showShareButton: true
  },
  matchingModuleConfiguration: {
    hasSmartmap: true,
    showDividingLine: true,
    showVoteMatchButton: true
  },
  questionnaireModuleConfiguration: { showWeight: true },
  questionsAboutYouModuleConfiguration: { showUserSurvey: true },
  demoModuleConfiguration: {
    layoverClosesAfterSeconds: 30,
    enabled: false,
    maxInactivityInSeconds: 180
  },
  showPrivacyLayover: true
}
