import { environment } from '../../environments/environment';
import { LayoutComponent } from './layout/layout.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { PolicyLayoverComponent } from './policy-layover/policy-layover.component';
import { LanguageMenuComponent } from './header/language-menu/language-menu.component';
import { LanguageOverlayComponent } from './header/language-menu/language-overlay/language-overlay.component';
import { iframeSettingsProvider } from './iframe';
var COMPONENTS = [
    LayoutComponent,
    FooterComponent,
    HeaderComponent,
    LanguageMenuComponent,
    LanguageOverlayComponent,
    PolicyLayoverComponent
];
var ɵ0 = iframeSettingsProvider, ɵ1 = typeof window !== 'undefined' ? window.sessionStorage : { setItem: function () { }, getItem: function () { } }, ɵ2 = typeof window !== 'undefined' ? window.sessionStorage : { setItem: function () { }, getItem: function () { } }, ɵ3 = environment.cms;
var CoreModule = /** @class */ (function () {
    function CoreModule() {
    }
    return CoreModule;
}());
export { CoreModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
