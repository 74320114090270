<div class="party info-item bold">
  {{ election.name }}
  <ng-container *ngIf="election"
    >| <ng-container>{{ candidate.party.name }}</ng-container
    >&nbsp;</ng-container
  >
  <ng-container *ngIf="isIncumbent(candidate)"
    >|
    <ng-container i18n="@@candidate.profile.incumbent">Incumbent</ng-container>&nbsp;</ng-container
  >
  <ng-container *ngIf="isElected(candidate)"
    >| <ng-container i18n="@@candidate.elected">Elected</ng-container>&nbsp;</ng-container
  >
</div>
<!-- <div *ngIf="candidate.district" class="info-item">
  <span class="bold" i18n="@@districtLabel">District:</span> {{ candidate.district.name }}
  <span *ngIf="districtGroup">({{ districtGroup.name }})</span>
</div> -->
<div *ngIf="candidate.residence" class="info-item">
  <span class="bold" i18n="@@candidate.residenceLabel">Residence:</span> {{ candidate.residence }}
</div>
<div *ngIf="candidate.profession" class="info-item">
  <span class="bold" i18n="@@candidate.professionLabel">Profession:</span>
  {{ candidate.profession }}
</div>
<div *ngIf="getValue(candidate, 'currentPoliticalPosition')" class="info-item">
  <span class="bold" i18n="@@candidate.currentPoliticalPositionLabel"
    >Current political Position:</span
  >
  {{ getValue(candidate, 'currentPoliticalPosition') }}
</div>
<div *ngIf="candidate.gender" class="info-item">
  <span class="bold" i18n="@@candidate.genderLabel">Gender:</span
  ><ng-container>&nbsp;</ng-container>
  <span *ngIf="candidate.gender === 1" i18n="@@candidate.genderFemale">Female</span>
  <span *ngIf="candidate.gender === 2" i18n="@@candidate.genderMale">Male</span>
  <span *ngIf="candidate.gender === 3" i18n="@@candidate.genderOther">Other</span>
</div>
<div *ngIf="candidate.yearOfBirth" class="info-item">
  <span class="bold" i18n="@@yearOfBirthLabel">Year of birth:</span> {{ candidate.yearOfBirth }}
</div>
