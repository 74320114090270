<div class="svi-database-filter-group">
  <form [formGroup]="form">
    <div class="svi-database-filter-sub-group">
      <div *ngIf="elections && elections.length > 1" class="form-group">
        <div class="form-title" i18n="@@filter.selectElection">Select Election</div>
        <select
          #selectElection
          [sviFocus]="elections.length > 1 && this.state.election === ''"
          (change)="resetDistrictGroup()"
          class="form-control form-control-wide"
          formControlName="election"
        >
          <option value="">-</option>
          <ng-container *ngIf="elections && elections.length > 1">
            <option *ngFor="let election of elections" [value]="election.id">
              {{ election.name }}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
    <ng-container *ngIf="!electionFilterOnly && election">
      <div class="svi-database-filter-sub-group">
        <div *ngIf="state.election !== '' && districtGroups[1]" class="form-group">
          <div class="form-title" i18n="@@filter.selectDistrictGroup">Select District Group</div>
          <select
            class="form-control form-control-wide"
            formControlName="districtGroup"
            (change)="resetDistrict()"
          >
            <option value="">-</option>
            <option *ngFor="let districtGroup of districtGroups" [value]="districtGroup.id">
              {{ districtGroup.name }}
            </option>
          </select>
        </div>
      </div>
      <div
        *ngIf="
          (state.election !== '' || !elections[1]) &&
          (state.districtGroup !== '' || !districtGroups[1])
        "
        class="svi-database-filter-sub-group"
      >
        <!-- <div class="form-group">
          <div class="form-title" i18n="@@filter.selectDistrict">Select District</div>
          <select
            #selectDistrict
            [sviFocus]="
              elections.length === 1 &&
              this.state.district === '' &&
              this.selectElection &&
              this.selectElection.nativeElement.hasFocus()
            "
            class="form-control form-control-wide"
            formControlName="district"
            [attr.disabled]="districts.length === 0 || districts.length === 1 ? true : null"
          >
            <option value="">-</option>
            <option *ngFor="let district of districts" [value]="district.id">
              {{ district.name }}
            </option>
          </select>
        </div> -->
      </div>
      <div class="svi-database-filter-sub-group">
        <div *ngIf="false && parties" class="form-group">
          <div class="form-title" i18n="@@filter.selectParty">Select Party</div>
          <select class="form-control" formControlName="party">
            <option value="">-</option>
            <option *ngFor="let party of parties" [value]="party.id">{{ party.name }}</option>
          </select>
        </div>
        <input
          type="text"
          class="form-control form-group no-title"
          placeholder="Name"
          i18n-placeholder="@@filter.candidateNamePlaceholder"
          formControlName="name"
        />
        <div class="form-group">
          <div class="form-title" i18n="@@filter.selectGender">Gender</div>
          <select class="form-control" formControlName="gender">
            <option value="">-</option>
            <option [value]="1" i18n="@@candidate.genderFemale">Female</option>
            <option [value]="2" i18n="@@candidate.genderMale">Male</option>
            <option [value]="3" i18n="@@candidate.genderOther">Other</option>
          </select>
        </div>
        <svi-switch-button
          class="switch"
          answerNo="New Candidates"
          i18n-answerNo="@@filter.newCandidates"
          answerYes="Incumbents"
          i18n-answerYes="@@filter.incumbents"
          (stateChanged)="setFormControlValue('incumbent', $event)"
        >
        </svi-switch-button>
        <svi-switch-button
          class="switch"
          answerYes="smartvote Profile"
          i18n-answerNo="@@filter.smartvoteProfile"
          answerNo="No Profile"
          i18n-answerYes="@@filter.noProfile"
          (stateChanged)="setFormControlValue('answersConfirmed', $event)"
        >
        </svi-switch-button>
        <svi-switch-button
          class="switch"
          answerYes="Elected"
          i18n-answerNo="@@filter.elected"
          answerNo="Not Elected"
          i18n-answerYes="@@filter.notElected"
          (stateChanged)="setFormControlValue('elected', $event)"
        >
        </svi-switch-button>
      </div>
      <div class="button-row">
        <button class="button button-primary icon-left" (click)="onSearch()" [disabled]="disabled">
          <i class="fa fa-search"></i>
          <ng-container i18n="@@search">Search</ng-container>
        </button>
        <button class="button button-primary" (click)="onReset()" [disabled]="hasStateDefaultValue">
          <ng-container i18n="@@reset">Reset</ng-container>
        </button>
      </div>
    </ng-container>
  </form>
</div>
