import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

import { environment } from '../../environments/environment'

import { LayoutComponent } from './layout/layout.component'
import { FooterComponent } from './footer/footer.component'
import { HeaderComponent } from './header/header.component'
import { PolicyLayoverComponent } from './policy-layover/policy-layover.component'
import { LanguageMenuComponent } from './header/language-menu/language-menu.component'
import { LanguageOverlayComponent } from './header/language-menu/language-overlay/language-overlay.component'
import { iframeSettingsProvider, IFRAME_SETTINGS } from './iframe'
import { TransferState } from '@angular/platform-browser'
import { SharedModule } from '../shared/shared.module'
import { LocalStorage, SessionStorage } from './tokens'
import { AnswerService } from './answer.service'
import { VoterIdService } from './voter-id.service'
import { ResearchIdService } from './research-id.service'
import { TrackingService } from './tracking.service'
import { CmsService, CmsSettingsService } from './cms.service'

const COMPONENTS = [
  LayoutComponent,
  FooterComponent,
  HeaderComponent,
  LanguageMenuComponent,
  LanguageOverlayComponent,
  PolicyLayoverComponent
]

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    {
      provide: IFRAME_SETTINGS,
      useFactory: iframeSettingsProvider,
      deps: [TransferState]
    },
    {
      provide: LocalStorage,
      useValue:
        typeof window !== 'undefined' ? window.sessionStorage : { setItem() {}, getItem() {} }
    },
    {
      provide: SessionStorage,
      useValue:
        typeof window !== 'undefined' ? window.sessionStorage : { setItem() {}, getItem() {} }
    },
    {
      provide: CmsSettingsService,
      useValue: environment.cms
    },
    AnswerService,
    VoterIdService,
    ResearchIdService,
    TrackingService,
    CmsService
  ],
  entryComponents: [LanguageOverlayComponent]
})
export class CoreModule {}
