<svi-navbar
  backLabel="Back to questionnaire"
  i18n-backLabel="@@backToQuestionnaire"
  backRoute="/matching"
></svi-navbar>
<svi-page-title i18n="@@matching.title">Your Individualized Results</svi-page-title>

<!-- Placeholder-->
<ng-template #hint>
  <svi-tab-group
    *ngIf="!loading"
    class="svi-matching-results"
    [(selectedIndex)]="tabIndex"
    [isPlaceholder]="true"
  >
    <svi-tab label="Ranking" i18n-label="@@matching.ranking">
      <div>
        <svi-list-item
          *ngFor="let item of [1,2,3,4]; let i = index"
          [isPlaceholder]="true"
          [matchingValue]="0"
          [class.inactive]="true"
        >
        </svi-list-item>
      </div>
    </svi-tab>
    <svi-tab *ngIf="config.hasSmartmap" label="smartmap"> </svi-tab>
  </svi-tab-group>
</ng-template>

<ng-template #resultsActions let-sharingInfo="sharingInfo">
  <div class="actions">
    <button
      sviSharingTrigger
      [sharingInfo]="sharingInfo"
      class="button d-none d-sm-inline-block"
      (click)="trackSharingTrigger()"
    >
      <i class="fa fa-share-alt"></i>
      <ng-container i18n="@@matching.shareResultsButton">Share Results</ng-container>
    </button>
    <button
      sviSharingTrigger
      [sharingInfo]="sharingInfo"
      class="button button-icon d-inline-block d-sm-none"
    >
      <i class="fa fa-share-alt"></i>
    </button>
  </div>
</ng-template>

<ng-template #notEnoughAnswersMessage i18n="@@matching.votematchNotEnoughAnswers">
  You must answer at least 15 of the first 16 questions.
</ng-template>

<svi-page-content>
  <div [ngClass]="{ hidden: loadingPrismic || loadingRecommendation }">
    <div class="top-panel">
      <div class="filtergroup">
        <svi-filter-group
          [disabled]="loadingRecommendation || loadingPrismic"
          [elections]="elections | async"
          [state]="filterGroupStateFromRecommendation"
          (stateChanged)="onFilterGroupChanged($event)"
        ></svi-filter-group>
        <ng-container *ngIf="matchingPageDoc | async">
          <div class="cms" [innerHTML]="(matchingPageDoc | async).html"></div>
        </ng-container>
      </div>
      <button
        *ngIf="config.showVoteMatchButton"
        class="button votematch"
        (click)="onVotematchClick()"
        [class.warning]="!enoughAnswers"
      >
        <ng-container
          *ngIf="enoughAnswers; else notEnoughAnswersMessage"
          i18n="@@matching.votematchEnoughAnswers"
        >
          See your results for all EU countries<br /><b>Go to VoteMatch.eu</b>
        </ng-container>
      </button>
    </div>
  </div>
</svi-page-content>

<svi-loading *ngIf="loadingRecommendation"></svi-loading>

<ng-container *ngIf="recommendation | async as recommendation; else hint">
  <svi-tab-group
    class="svi-matching-results"
    (selectedTabChanged)="onTabChanged($event)"
    [(selectedIndex)]="tabIndex"
  >
    <svi-tab label="Ranking" i18n-label="@@matching.ranking">
      <!-- Sharing Info, set meta
        tags only if this tab is active -->
      <ng-container
        #sharingInfo="sviSharingInfo"
        sviSharingInfo
        [setMetaTags]="tabIndex === 0"
        title="smartvote international demo"
        i18n-title="@@matching.sharingTitle"
        description="Here is my voting advice for the smartvote international demo"
        i18n-description="@@matching.sharingDescription"
        [imagePath]="'/assets/images/svint_demo_static_sharing_image_' + localeId + '.jpg'"
        [sharePath]="'/' + localeId + '/share/results?tab=0&rid=' + recommendation.id"
      ></ng-container>

      <ng-container
        *ngTemplateOutlet="resultsActions; context: {sharingInfo: sharingInfo}"
      ></ng-container>
      <div #sharingElement="sviSharingElement" sviSharingElement>
        <ng-container *ngIf="recommendation.matchings?.length > 0; else noMatch">
          <svi-list-item
            *ngFor="let matching of recommendation.matchings; let i = index"
            [class.lastseat]="config.showDividingLine && recommendation.options.responderType === 'Candidate' && i === nofSeats - 1"
            [class.inactive]="config.showDividingLine && recommendation.options.responderType === 'Candidate' && i > nofSeats - 1"
            [photoUrl]="matching.responder ? matching.responder.photoUrl : null"
            [rank]="i + 1"
            [title]="matching.title"
            [description]="matching.description"
            [isDirectory]="false"
            [hasMatching]="(matching.matchValue && matching.matchValue !== -9) || matching.matchValue === 0"
            [matchingValue]="matching.matchValue"
            [isCandidate]="recommendation.options.responderType === 'Candidate'"
            (click)="onMatchingSelected(matching)"
          >
          </svi-list-item>
        </ng-container>
      </div>
    </svi-tab>
    <svi-tab *ngIf="config.hasSmartmap" label="smartmap">
      <!-- Sharing Info, set meta
        tags only if this tab is active -->
      <div class="checkbox-wrapper">
        <svi-custom-checkbox
          [checked]="showVoterPosition"
          (valueChanged)="showVoterPosition = $event"
          childId="my-smartmap-position"
        >
          <span i18n="@@matching.mySmartmapPosition">Meine smartmap Position</span>
        </svi-custom-checkbox>
      </div>
      <ng-container
        #sharingInfo2="sviSharingInfo"
        sviSharingInfo
        [setMetaTags]="tabIndex === 1"
        title="smartvote international demo"
        i18n-title="@@smartmap.sharingTitle"
        description="Here is my smartmap for the smartvote international demo"
        i18n-description="@@smartmap.sharingDescription"
        [imageFromElement]="sharingElement2"
        [sharePath]="'/' + localeId + '/share/results?tab=1&rid=' + recommendation.id"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="resultsActions; context: {sharingInfo: sharingInfo2}"
      ></ng-container>

      <div class="smartmap">
        <ng-container *ngIf="elections | async as elections;">
          <sv-smartmap
            #sharingElement2="sviSharingElement"
            sviSharingElement
            [screenshotHeight]="560"
            [positions]="recommendation.positions"
            [labelLeft]="elections[0].dimensions[0].pole1"
            [myPosition]="showVoterPosition ? recommendation.voter.smartmapPosition : null"
            [labelRight]="elections[0].dimensions[0].pole2"
            [labelTop]="elections[0].dimensions[1].pole2"
            [labelBottom]="elections[0].dimensions[1].pole1"
            (onSelect)="onSmartmapItemSelect($event)"
          ></sv-smartmap>
        </ng-container>
        <ul class="smartmap-legend">
          <li *ngFor="let item of recommendation.smartmapLegendItems">
            <svi-chart-legend-item
              [label]="item.label"
              [color]="item.color"
            ></svi-chart-legend-item>
          </li>
        </ul>
        <svi-smartmap-methodology></svi-smartmap-methodology>
      </div>
    </svi-tab>
  </svi-tab-group>
  <div class="svi-matching-result-summary">
    <div class="item">
      <ng-container i18n="@@matching.numberOfSeats">No. of seats</ng-container>:
      {{resultSummary.nofSeats}}
    </div>
    <div class="item">
      <ng-container i18n="@@matching.numberOfCandidates">No. of candidates</ng-container>:
      {{resultSummary.nofCandidates}}
    </div>
    <!-- <div class="item">
      <ng-container i18n>No. of participating candidates</ng-container>: {{resultSummary.nofParticipatingCandidates}}
    </div> -->
  </div>
</ng-container>

<ng-template #noMatch>
  <div class="no-match" i18n="@@matching.noMatchesFound">No matches found</div>
</ng-template>

<ng-template #translations>
  <span id="no-information" i18n="@@matching.noInformation">No information</span>
  <span id="my-position" i18n="@@matching.myPosition">My position</span>
</ng-template>
