/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../profile/profile-with-voter.component.ngfactory";
import * as i2 from "../profile/profile-with-voter.component";
import * as i3 from "@angular/router";
import * as i4 from "../profile/profile.module";
import * as i5 from "../profile/profile.component.ngfactory";
import * as i6 from "../profile/profile.component";
import * as i7 from "../shared/page-content/page-content.component.ngfactory";
import * as i8 from "../shared/page-content/page-content.component";
import * as i9 from "@angular/common";
import * as i10 from "../shared/loading/loading.component.ngfactory";
import * as i11 from "../shared/loading/loading.component";
import * as i12 from "../shared/navbar/navbar.component.ngfactory";
import * as i13 from "../shared/navbar/navbar.component";
import * as i14 from "../core/tokens";
import * as i15 from "../shared/page-title/page-title.component.ngfactory";
import * as i16 from "../shared/page-title/page-title.component";
import * as i17 from "./party-details.page";
import * as i18 from "../core/voter-id.service";
import * as i19 from "apollo-angular";
var styles_PartyDetailsPage = [];
var RenderType_PartyDetailsPage = i0.ɵcrt({ encapsulation: 2, styles: styles_PartyDetailsPage, data: {} });
export { RenderType_PartyDetailsPage as RenderType_PartyDetailsPage };
function View_PartyDetailsPage_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-profile-with-voter", [], null, [[null, "selectedTabChanged"], [null, "tabIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChanged" === en)) {
        var pd_0 = (_co.onTabChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("tabIndexChange" === en)) {
        var pd_1 = ((_co.tabIndex = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ProfileWithVoterComponent_0, i1.RenderType_ProfileWithVoterComponent)), i0.ɵdid(1, 638976, null, 0, i2.ProfileWithVoterComponent, [i3.Router, i4.PROFILE_MODULE_CONFIG, i0.LOCALE_ID], { tabIndex: [0, "tabIndex"], voter: [1, "voter"], questions: [2, "questions"], responder: [3, "responder"] }, { selectedTabChanged: "selectedTabChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabIndex; var currVal_1 = _v.parent.context.ngIf.voter; var currVal_2 = _v.parent.context.ngIf.questions; var currVal_3 = _v.parent.context.ngIf.party; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PartyDetailsPage_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-profile", [], null, [[null, "selectedTabChanged"], [null, "tabIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChanged" === en)) {
        var pd_0 = (_co.onTabChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("tabIndexChange" === en)) {
        var pd_1 = ((_co.tabIndex = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_ProfileComponent_0, i5.RenderType_ProfileComponent)), i0.ɵdid(1, 638976, null, 0, i6.ProfileComponent, [i4.PROFILE_MODULE_CONFIG, i3.Router, i0.LOCALE_ID], { tabIndex: [0, "tabIndex"], voter: [1, "voter"], questions: [2, "questions"], responder: [3, "responder"] }, { selectedTabChanged: "selectedTabChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabIndex; var currVal_1 = _v.parent.context.ngIf.voter; var currVal_2 = _v.parent.context.ngIf.questions; var currVal_3 = _v.parent.context.ngIf.party; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PartyDetailsPage_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "svi-page-content", [], null, null, null, i7.View_PageContentComponent_0, i7.RenderType_PageContentComponent)), i0.ɵdid(1, 49152, null, 0, i8.PageContentComponent, [], null, null), (_l()(), i0.ɵted(-1, 0, ["Profile not available"]))], null, null); }
function View_PartyDetailsPage_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PartyDetailsPage_2)), i0.ɵdid(2, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PartyDetailsPage_3)), i0.ɵdid(4, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PartyDetailsPage_4)), i0.ɵdid(6, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.ngIf.party && _v.context.ngIf.voter); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.ngIf.party && !_v.context.ngIf.voter); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_v.context.ngIf.party; _ck(_v, 6, 0, currVal_2); }, null); }
function View_PartyDetailsPage_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "svi-page-content", [], null, null, null, i7.View_PageContentComponent_0, i7.RenderType_PageContentComponent)), i0.ɵdid(1, 49152, null, 0, i8.PageContentComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "svi-loading", [], null, null, null, i10.View_LoadingComponent_0, i10.RenderType_LoadingComponent)), i0.ɵdid(3, 49152, null, 0, i11.LoadingComponent, [], null, null)], null, null); }
export function View_PartyDetailsPage_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-navbar", [["backLabel", "Back to results"]], null, null, null, i12.View_NavbarComponent_0, i12.RenderType_NavbarComponent)), i0.ɵdid(1, 49152, null, 0, i13.NavbarComponent, [i9.Location, i3.Router, i14.LocalStorage], { backLabel: [0, "backLabel"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "svi-page-title", [], null, null, null, i15.View_PageTitleComponent_0, i15.RenderType_PageTitleComponent)), i0.ɵdid(3, 49152, [["title", 4]], 0, i16.PageTitleComponent, [], null, null), (_l()(), i0.ɵted(-1, 0, ["Profile"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_PartyDetailsPage_1)), i0.ɵdid(6, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i9.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["loading", 2]], null, 0, null, View_PartyDetailsPage_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Back to results"; _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.data)); var currVal_2 = i0.ɵnov(_v, 8); _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_PartyDetailsPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-party-details", [], null, null, null, View_PartyDetailsPage_0, RenderType_PartyDetailsPage)), i0.ɵdid(1, 4243456, null, 0, i17.PartyDetailsPage, [i3.ActivatedRoute, i3.Router, i18.VoterIdService, i19.Apollo, i0.PLATFORM_ID], null, null)], null, null); }
var PartyDetailsPageNgFactory = i0.ɵccf("svi-party-details", i17.PartyDetailsPage, View_PartyDetailsPage_Host_0, {}, {}, []);
export { PartyDetailsPageNgFactory as PartyDetailsPageNgFactory };
