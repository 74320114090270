export const MAPPING = [
  { svId: 1, votematchId: 'answer24' },
  { svId: 2, votematchId: 'answer13' },
  { svId: 3, votematchId: 'answer04' },
  { svId: 4, votematchId: 'answer01' },
  { svId: 5, votematchId: 'answer07' },
  { svId: 6, votematchId: 'answer09' },
  { svId: 7, votematchId: 'answer19' },
  { svId: 8, votematchId: 'answer20' },
  { svId: 9, votematchId: 'answer23' },
  { svId: 10, votematchId: 'answer25' },
  { svId: 11, votematchId: 'answer15' },
  { svId: 12, votematchId: 'answer05' },
  { svId: 13, votematchId: 'answer11' },
  { svId: 14, votematchId: 'answer10' },
  { svId: 15, votematchId: 'answer18' },
  { svId: 16, votematchId: 'answer22' },
  { svId: 17, votematchId: 'answer16' },
  { svId: 18, votematchId: 'answer03' },
  { svId: 19, votematchId: 'answer06' },
  { svId: 20, votematchId: 'answer02' },
  { svId: 21, votematchId: 'answer17' },
  { svId: 22, votematchId: 'answer12' },
  { svId: 23, votematchId: 'answer14' },
  { svId: 24, votematchId: 'answer08' },
  { svId: 25, votematchId: 'answer21' }
]
